:root {
  --background-color: #121212;
  --navbar-background-color: rgba(0, 0, 0, 0.7);
  --text-color: #ffffff;
  --primary-color: #768f83;
}

.light-theme {
  --background-color: #ffffff;
  --navbar-background-color: rgba(255, 255, 255, 0.7);
  --text-color: #000000;
  --primary-color: #d75c37;
}

.christmas-theme {
  --background-color: rgba(124, 17, 17, 0.8);
  --navbar-background-color: rgba(124, 17, 17, 0.8);
  --text-color: #ffffff;
  --primary-color: #388e3c;
}

body {
  margin: 0 !important;
  padding: 0 !important;
  background: var(--background-color);
  background-color: var(--background-color);
  color: var(--text-color);
}
